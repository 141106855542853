import React from "react";
import classNames from "classnames";

import Placeholder, { Rect } from "components/Placeholder";

type Props = {
  className?: string;
};

const DealCardPlaceholder: React.FC<Props> = ({ className, ...others }) => (
  <div
    className={classNames("rounded overflow-hidden shadow-low", className)}
    {...others}
  >
    <Placeholder width="100%" height="160">
      <Rect width="100%" height="100%" />
    </Placeholder>
    <div className="p-lg">
      <Placeholder width="100%" height="113">
        <Rect width="85%" height="20" />
        <Rect y="24" width="65%" height="20" />
        <Rect y="56" width="75%" height="13" />
        <Rect y="74" width="50%" height="20" />
        <Rect y="99" width="40" height="13" />
      </Placeholder>
    </div>
  </div>
);

export default DealCardPlaceholder;
