import React from "react";
import classNames from "classnames";
import { format, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import Link from "next/link";
import { useSelector } from "react-redux";

import { CATEGORY_NAMES } from "constants/dispensary";
import { Deal } from "custom-types/Deals";
import { getRecreationalAlternateTerm } from "redux/selectors/complianceRules";
import { getDistanceCustomRounding } from "utils/distance";
import { MENU_TITLES } from "utils/menuTypeUtils";

import StorefrontIcon from "components/Icons/storefront.svg";
import Image from "components/Image";
import OfferLabel from "components/OfferLabel";

export type DealType = Deal & {
  dispensaryTimeZone?: string;
  kind?: string;
  menuItemCategories?: string[];
  shortDisplayTitle?: string;
};
export type DealCardProps = {
  as?: React.ElementType;
  children?: string;
  className?: string;
  countryCode?: string;
  disableLazyLoad?: boolean;
  deal: DealType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  onClick?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  onLoad?: (...args: any[]) => any;
  showFulfillment?: boolean;
  showMenuType?: boolean;
};

const DealCard: React.FC<DealCardProps> = ({
  deal,
  onClick,
  showFulfillment,
  showMenuType,
  className,
  countryCode = "US",
  disableLazyLoad = false,
  as = "a",
  ...others
}) => {
  const {
    daysOfWeek,
    active: dealActive,
    dealUrl,
    discountLabel,
    dispensary,
    dispensaryTimeZone,
    endsAt,
    imageUrl,
    isMedical,
    isRecreational,
    menuItemCategories,
    title,
    shortDisplayTitle,
  } = deal;

  const {
    distanceMi,
    name: dispensaryName,
    hasDeliveryEnabled,
    hasReservationsEnabled,
  } = dispensary;

  const recreationalTermOverride = useSelector(getRecreationalAlternateTerm);

  const formattedDistance = `${getDistanceCustomRounding(
    distanceMi,
    countryCode,
    true,
  )} away`;

  // Filter and convert category names to readable versions ordered by popularity
  const categories = menuItemCategories?.length
    ? Object.keys(CATEGORY_NAMES).reduce(
        (array, category) =>
          menuItemCategories.includes(category)
            ? [...array, CATEGORY_NAMES[category]]
            : array,
        [] as string[],
      )
    : [];

  const timeZone = dispensary?.timeZone || dispensaryTimeZone;

  const dispensaryDistance = () => {
    if (hasDeliveryEnabled && hasReservationsEnabled) {
      return `${formattedDistance} ▪ delivery`;
    } else if (hasDeliveryEnabled) {
      return "delivery only";
    }
    return formattedDistance;
  };

  let medRecText =
    isMedical && isRecreational
      ? MENU_TITLES.dual.shortTitle.toUpperCase()
      : isMedical
        ? MENU_TITLES.med.shortTitle.toUpperCase()
        : isRecreational
          ? MENU_TITLES.rec.shortTitle.toUpperCase()
          : null;

  if (medRecText && recreationalTermOverride) {
    medRecText = medRecText.replaceAll(
      MENU_TITLES.rec.shortTitle.toUpperCase(),
      recreationalTermOverride.toUpperCase(),
    );
  }

  const handleClick = () => {
    onClick?.();
  };

  const Element = as === "a" && dealUrl ? Link : "div";
  return (
    <div className="h-full" {...others}>
      <Element
        href={dealUrl || ""}
        className={classNames("flex flex-col bg-white", className)}
        onClick={handleClick}
        data-testid="deal-carousel__card"
      >
        <div className="relative card__image flex-shrink-0 h-[unset] aspect-[16/9]">
          <Image
            disableLazyLoad={disableLazyLoad}
            src={imageUrl}
            sizes={[225, null, 300, 240, null, 225]}
            alt={`${title} image`}
            background
          />
          {showMenuType && !!medRecText && (
            <div
              className="absolute top-0 right-0 mt-sm mr-sm text-xs font-bold bg-white border border-light-grey rounded px-xs"
              data-testid="deal-card-menu-type"
            >
              {medRecText}
            </div>
          )}
        </div>

        <div className="p-lg flex justify-between flex-col h-full">
          <div>
            {dealActive && (
              <div className="flex mb-sm">
                {shortDisplayTitle ? (
                  <OfferLabel label={shortDisplayTitle} />
                ) : (
                  <div
                    data-testid="deal-card__discount-label"
                    className="bg-notification rounded text-xs text-white font-bold"
                    style={{ padding: "2px 4px" }}
                  >
                    {discountLabel}
                  </div>
                )}
              </div>
            )}

            {categories?.length ? (
              <ul className="flex flex-wrap text-grey text-xs mb-xs leading-none">
                {categories.map((category) => (
                  <li
                    key={category}
                    className="[&:not(:last-child)]:after:content-['_•_'] [&:not(:last-child)]:after:whitespace-pre"
                  >
                    {category}
                  </li>
                ))}
              </ul>
            ) : (
              <div className="leading-none">&nbsp;</div>
            )}

            <div className="font-bold mb-sm">{title}</div>
          </div>

          <div>
            {daysOfWeek && (
              <div className="text-grey text-xs mb-xs">
                {validDates(daysOfWeek, endsAt, timeZone)}
              </div>
            )}

            {showFulfillment && (
              <div className="flex items-center text-xs">
                <StorefrontIcon
                  height="16"
                  width="16"
                  className="flex-shrink-0 mr-xs"
                />
                <div className="truncate">
                  <div className="truncate font-bold">{dispensaryName}</div>
                  {formattedDistance && (
                    <span className="truncate text-grey">
                      <div data-testid="deal-card__dispensary-distance">
                        {dispensaryDistance()}
                      </div>
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Element>
    </div>
  );
};

export function validDates(
  daysOfWeek: unknown[],
  endsAt: string | null,
  timeZone?: string,
) {
  const dayCount = daysOfWeek.length;
  /* eslint-disable sort-keys-fix/sort-keys-fix -- expection: necessary for display */
  const dayEnum = {
    sunday: ["Sundays", "Sun", "Y"],
    monday: ["Mondays", "Mon", "M"],
    tuesday: ["Tuesdays", "Tue", "T"],
    wednesday: ["Wednesdays", "Wed", "W"],
    thursday: ["Thursdays", "Thu", "R"],
    friday: ["Fridays", "Fri", "F"],
    saturday: ["Saturdays", "Sat", "S"],
  };
  /* eslint-enable sort-keys-fix/sort-keys-fix */

  // @ts-ignore (fix me please, do not replicate)
  function getFormattedDayOfWeek(dayOfWeek, format) {
    const formats = {
      long: 0,
      medium: 1,
      short: 2,
    };

    // @ts-ignore (fix me please, do not replicate)
    const dayArray = dayEnum[dayOfWeek];
    // @ts-ignore (fix me please, do not replicate)
    const dayIndex = formats[format];
    return dayArray[dayIndex];
  }

  let availableText = "";

  if (dayCount === 1) {
    availableText = `Available ${getFormattedDayOfWeek(daysOfWeek[0], "long")}`;
  }
  if (dayCount === 2) {
    availableText = `Available ${getFormattedDayOfWeek(
      daysOfWeek[0],
      "medium",
    )} & ${getFormattedDayOfWeek(daysOfWeek[1], "medium")}`;
  }
  if (dayCount > 2 && dayCount < 7) {
    const dayKeys = Object.keys(dayEnum);

    //This piece formats the days available by grouping consecutive days with a dash.
    let linked = false; //Keeps track of consecutive days
    let linkCount = 0; //Keep track of how many consecutive days since we only want to group more than 2 consecutive
    for (let i = 0; i < dayKeys.length; i++) {
      if (daysOfWeek.includes(dayKeys[i])) {
        if (linked) {
          linkCount++;
          // if the day is saturday we need to handle differently.
          if (i === dayKeys.length - 1) {
            if (linkCount === 2) {
              availableText += `, ${getFormattedDayOfWeek(
                dayKeys[i],
                "medium",
              )}`;
            } else if (linkCount > 2) {
              availableText += `-${getFormattedDayOfWeek(
                dayKeys[i],
                "medium",
              )}`;
            }
          }
        } else {
          linked = true;
          linkCount = 1;
          if (availableText === "") {
            availableText += getFormattedDayOfWeek(dayKeys[i], "medium");
          } else {
            availableText += `, ${getFormattedDayOfWeek(dayKeys[i], "medium")}`;
          }
        }
      } else if (linked) {
        if (linkCount === 2) {
          availableText += `, ${getFormattedDayOfWeek(
            dayKeys[i - 1],
            "medium",
          )}`;
        } else if (linkCount > 2) {
          availableText += `-${getFormattedDayOfWeek(
            dayKeys[i - 1],
            "medium",
          )}`;
        }
        linked = false;
        linkCount = 0;
      }
    }
    availableText = `Available ${availableText}`;
  }
  if (dayCount === 7) {
    availableText = `Available daily`;
  }

  if (endsAt) {
    let endsAtFormatted;

    if (timeZone) {
      endsAtFormatted = format(utcToZonedTime(endsAt, timeZone), "L/d");
    } else {
      endsAtFormatted = format(parseISO(endsAt), "L/d");
    }

    availableText = `${availableText} until ${endsAtFormatted}`;
  }

  return availableText;
}

export default DealCard;
