import React from "react";

import { Deal } from "custom-types/Deals";

const useGetDealKindLabel = () => {
  const getDealKindLabel = React.useCallback((deal: Deal) => {
    switch (deal.kind) {
      case "bogo":
        return `buy ${deal.buyQuantity}, get ${deal.getQuantity}`;
      case "bundle":
        return `${deal.buyQuantity} for ${deal.discountAmount} ${deal.discountType}`;
      default:
        return `${deal.discountAmount} ${deal.discountType}`;
    }
  }, []);

  return {
    getDealKindLabel,
  };
};

export default useGetDealKindLabel;
