import React from "react";

import { Dispensary, RetailType } from "custom-types/Dispensary";
import useDomain from "hooks/useDomain";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { buildLocationUrls } from "utils/buildLocationUrls";
import { getRetailerInfo } from "utils/getRetailerInfo";
import { retailCopyFormatter } from "utils/retailCopyFormatter";

import Breadcrumb from "components/Breadcrumb";
import Breadcrumbs from "components/Breadcrumbs";

const DispensaryBreadcrumbs: React.FC<{
  product?: string;
  dispensary: Dispensary;
}> = ({ product, dispensary }) => {
  const {
    deliveryEnabled,
    locationPaths,
    name,
    pickupEnabled,
    retailType,
    slug,
  } = dispensary;
  const countryCode = useDomainCountryCode();
  const isDeliveryOnly = !pickupEnabled && deliveryEnabled;
  const isDispensaryRetailer = retailType === "dispensary";
  const showDeliveryBreadcrumbs = isDispensaryRetailer && isDeliveryOnly;

  const baseUrl = useDomain();

  const retailerInfo = getRetailerInfo(countryCode, retailType as RetailType);
  const { copy, finderUrl, url } = retailerInfo;
  const breadcrumbUrl = showDeliveryBreadcrumbs ? "/delivery" : finderUrl;

  const retailerUrl = `${baseUrl}${url}/${slug}`;
  const retailerBreadcrumb = showDeliveryBreadcrumbs
    ? "Delivery"
    : retailCopyFormatter(copy, "all");

  const { cityUrl, stateUrl } = buildLocationUrls({
    baseUrl: breadcrumbUrl,
    locationPaths,
  });

  return (
    <Breadcrumbs>
      <Breadcrumb href={breadcrumbUrl} testId="breadcrumb-retailer">
        {retailerBreadcrumb}
      </Breadcrumb>

      {!!stateUrl && (
        <Breadcrumb href={stateUrl} testId="breadcrumb-state">
          {locationPaths?.stateDisplayName}
        </Breadcrumb>
      )}

      {!!cityUrl && (
        <Breadcrumb href={cityUrl} testId="breadcrumb-city">
          {locationPaths?.cityDisplayName}
        </Breadcrumb>
      )}

      {!!name && (
        <Breadcrumb
          href={retailerUrl}
          currentLocation={!product}
          testId="breadcrumb-name"
        >
          {name}
        </Breadcrumb>
      )}

      {!!product && (
        <Breadcrumb currentLocation={true} testId="breadcrumb-product">
          {product}
        </Breadcrumb>
      )}
    </Breadcrumbs>
  );
};

export default DispensaryBreadcrumbs;
