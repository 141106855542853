import { LocationPaths } from "custom-types/LocationPaths";

export const buildLocationUrls = ({
  baseUrl = "",
  locationPaths,
}: {
  baseUrl?: string;
  locationPaths?: Partial<LocationPaths>;
}) => {
  const stateUrl =
    locationPaths?.statePath && `${baseUrl}${locationPaths.statePath}`;
  const cityUrl =
    locationPaths?.cityPath && `${baseUrl}${locationPaths.cityPath}`;

  return {
    cityUrl,
    stateUrl,
  };
};
