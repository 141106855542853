import { Deal } from "custom-types/Deals";

const filterActiveDeals = (deals: Deal[]) =>
  deals.filter((deal) => deal.active);

const filterInactiveDeals = (deals: Deal[]) =>
  deals.filter((deal) => !deal.active);

export default (deals: Deal[]) => {
  // dispensaries can set a deal to "both" in biz which sets
  // isMedical and isRecreational to true. However, long standing
  // deals were not backfilled, and can have both vales set to false.
  // These should also be handled as "both" deals
  const medDeals = deals.filter(
    (deal) => deal.isMedical || (!deal.isMedical && !deal.isRecreational),
  );
  const recDeals = deals.filter(
    (deal) => deal.isRecreational || (!deal.isMedical && !deal.isRecreational),
  );

  return {
    todaysDeals: filterActiveDeals(deals),
    todaysMedDeals: filterActiveDeals(medDeals),
    todaysRecDeals: filterActiveDeals(recDeals),
    upcomingDeals: filterInactiveDeals(deals),
    upcomingMedDeals: filterInactiveDeals(medDeals),
    upcomingRecDeals: filterInactiveDeals(recDeals),
  };
};
