import React, { ComponentProps } from "react";
import Link from "next/link";

import ChevronIcon from "components/Icons/ChevronIcon";

export type BreadcrumbProps = {
  arrowWidth?: number;
  children?: React.ReactNode;
  /**
   * The breadcrumb that represents the current page should be marked with the
   * `currentLocation` prop. This will both add the required ARIA attributes
   * and style the breadcrumb correctly. This should only ever be used on the
   * final breadcrumb in the sequence.
   */
  currentLocation?: boolean;
  href?: string;
  testId?: string;
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  arrowWidth = 14,
  children,
  currentLocation = false,
  href,
  testId,
}) => {
  const showNavigationalArrow = !currentLocation;

  const validHref = href as ComponentProps<typeof Link>["href"];
  const Element = validHref ? Link : "span";

  return (
    <li className="flex flex-shrink-0 font-bold items-center text-xs whitespace-nowrap">
      <Element
        aria-current={currentLocation ? "location" : undefined}
        className="bg-none w-auto p-0 text-xs underline last:no-underline"
        href={validHref}
        data-testid={testId}
      >
        {children}
      </Element>
      {showNavigationalArrow && (
        <ChevronIcon
          width={`${arrowWidth}`}
          height={`${arrowWidth}`}
          direction="right"
        />
      )}
    </li>
  );
};

export default Breadcrumb;
